@import 'variables';
@import 'mixins';

.registered-infos {
  margin-bottom: 30px;
  strong {
    color: #000; }
  p {
    margin-bottom: 10px; } }

.infos-left, .infos-right {
  color: $dark-grey;
  display: inline-block;
  float: left;
  font: {
    family: PoppinsMedium;
    size: 12px; }
  line-height: 15px;
  width: 50%; }

.edit-profile-cont {
  padding-right: 15px; }
.edit-password-cont {
  padding-left: 15px; }

.infos-left {
  padding-right: 15px; }
.infos-right {
  padding-left: 15px;
  .orange-button {
    display: inline-block;
    padding: 2px 15px;
    width: auto; } }
.edit-column {
  display: inline-block;
  float: left;
  width: 50%; }

.edit-panel {
  border: 1px solid $dark-blue;
  padding: 20px;
  label {
    color: $dark-grey;
    font: {
      family: PoppinsRegular;
      size: 13px; }
    margin-bottom: 5px; }
  input {
    background-color: $clear-white;
    border: 0;
    font-size: 13px;
    height: 34px;
    outline: none;
    padding-left: 10px;
    width: 100%; }
  .edit-row {
    margin-bottom: 15px; } }
.panel {
  background-color: $clear-white;
  margin-bottom: 15px;
  padding: 12px 0 7px 0;
  .infos-left, .infos-right {
    text-align: center;
    padding-left: 0;
    padding-right: 0; }
  .infos-right {
    padding-top: 15px; }
  img {
    width: 160px; } }

.panel-title {
  background-color: $dark-blue;
  height: 29px;
  line-height: 29px;
  color: #FFF;
  padding-left: 12px;
  padding-top: 2px;
  font: {
    family: PoppinsMedium;
    size: 13px; } }


.message-item {
  font: {
    family: PoppinsMedium;
    size: 12px; }
  line-height: 15px;
  padding: 12px;
  &:nth-child(even) {
    background-color: $clear-white; }

  .message-date, .message-subject {
    margin-bottom: 10px; } }

@media (max-width: 577px) {
  .infos-left, .infos-right {
    padding: 0;
    width: 100%; }
  .infos-left {
    margin-bottom: 10px; }
  .panel {
    display: flex;
    flex-direction: column;
    .infos-left {
      order: 2; }
    .infos-right {
      order: 1;
      padding-bottom: 30px; } }

  .edit-column {
    padding: 0;
    width: 100%;
    &:first-child {
      margin-bottom: 15px; } } }
