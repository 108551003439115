@import 'variables';

.main-footer {
  background-color: $footer;
  color: $white;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  a {
    color: #FFF;
    font: {
      family: PoppinsSemibold;
      size: 13px; }
    margin: 0 5px;
    text-decoration: none; } }

.footer {
  background-color: $footer;
  color: $white;
  padding-bottom: 8px;
  text-align: center;
  width: 100%;
  a {
    color: #FFF;
    text-decoration: none;
    &:hover, &:active, &:focus {
      text-decoration: none; } }
  p {
    color: #FFF;
    font: {
      family: PoppinsMedium;
      size: 11px; }
    line-height: 15px;
    margin-bottom: 18px;
    &.adresse {
      display: block;
      font: {
        family: PoppinsMedium;
        size: 13px; }
      margin: 20px auto 0; } } }


.fb-banner {
  background-color: $nav-blue;
  font: {
    family: PoppinsMedium;
    size: 15px; }
  padding: 9px 0 15px 0;
  text-align: center;
  a {
    color: #FFF;
    &:hover, &:active, &:focus {
      text-decoration: none; } } }

.facebook-footer {
  margin-right: 10px;
  position: relative;
  top: 6px;
  width: 22px; }

.footer-wrapper {
  padding: 30px 0 0;
  text-align: center; }

.footer-sogima-logo {
  display: block;
  height: 22px;
  margin: 0 auto; }

@media screen and (max-width: 767px) {
  a {
    display: block; }

  .footer {
    a {
      margin: 0; } }

  .mobile-rotate {
    display: inline-block;
    @include transform(rotate(90deg));
    margin: 10px 0; } }


