@import 'variables';

.files-list {
  li {
    margin-bottom: 5px; } }

@media (max-width: 577px) {
  .orange-button.download {
    height: auto;
    line-height: 15px;
    padding: 10px 51px 10px 10px;
    word-break: break-all; } }
