@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  -moz-transform: $property;
  transform: $property; }

@mixin transition($property) {
  -webkit-transition: $property;
  -ms-transition: $property;
  -moz-transition: $property;
  transition: $property; }

@mixin border-radius($property) {
  border-radius: $property;
  -moz-border-radius: $property;
  -webkit-border-radius: $property; }

@mixin filter($property) {
  filter: $property;
  -webkit-filter: $property; }

@mixin backface-visibility($property) {
  backface-visibility: $property;
  -moz-backface-visibility: $property;
  -webkit-backface-visibility: $property; }

@mixin perspective($property) {
  perspective: $property;
  -moz-perspective: $property;
  -webkit-perspective: $property; }
