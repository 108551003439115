@import 'variables';

.componentContainer {
  margin-bottom: 5px; }

.customer-column {
  float: left;
  padding: 15px;
  width: 33.3%; }

.customerCard {
  border-radius: 4px;
  border: 1px solid $block-border;
  display: block;
  min-height: 110px;
  margin-bottom: 1px;
  padding: 10px;
  width: 100%;
  transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  &.fb-object-card {
    height: 180px; }
  &:hover {
    background-color: #dddfe229; }
  .idCard {
    display: block;
    width: 100%; } }

.card-field {
  font-size: 13px;
  margin-bottom: 5px; }

.ReactTable {
  border: 0;
  margin-top: -1px;
  text-align: center;
  height: 100%; }

.create-customer {
  .ui.input {
    display: block;
    width: 100%; }
  .ui.labeled.input>.label:not(.corner) {
    padding: 0;
    width: 100%; }

  .ui.labeled.input>.label {
    font-size: 14px;
    font-weight: normal; } }
