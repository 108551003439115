@import 'variables';
@import 'mixins';

/* Override react-select style */

.custom-react-select {
  cursor: pointer;
  width: 160px;
  * {
    cursor: pointer !important; }
  .css-d8oujb {
    display: none; }

  .css-vj8t7z {
    background-color: $blue;
    border-color: $blue;
    @include border-radius(3px);
    height: 38px;
    &:hover {
      border-color: $blue; } }

  .css-1492t68 {
    color: #FFF;
    font: {
      family: PoppinsSemibold;
      size: 13px; } }

  .css-1hwfws3 {
    height: 100%; }

  .css-2o5izw, .css-2o5izw:hover {
    background-color:  $blue;
    border-color: $blue;
    box-shadow: none;
    height: 38px; }

  .css-xp4uvy {
    color: #FFF;
    font: {
      family: PoppinsSemibold;
      size: 13px; }
    overflow: initial; }

  /* List drop down */
  .css-15k3avv {
    margin-top: 0;
    * {
      background-color: transparent;
      color: $dark-grey; } }
  /* Arrow */
  .css-1ep9fjw, .css-1uq0kb5 {
    background: {
      image: url('/img/caret.png');
      repeat: no-repeat;
      size: 34px;
      position: right center; }
    width: 27px;
    svg {
      display: none; } } }

.page-content.form-content {
  .custom-react-select {
    .css-vj8t7z {
      border: 1px solid #F1F1F1 !important;
      border-radius: 0;
      height: 34px;
      min-height: 34px;
      max-height: 34px; }
    .css-xp4uvy {
      top: 55%; }
    .css-2o5izw {
      box-sizing: border-box;
      border-radius: 0;
      min-height: 34px;
      max-height: 34px;
      &:hover {
        border: 0 !important;
        height: 34px; } } } }
