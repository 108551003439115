@import 'variables';
@import 'mixins';

.Modal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 5% auto;
  width: 80%;
  outline: inherit;
  background-color: #ffffff;
  border: 1px solid #707070;
  padding: 30px;
  color: $dark-grey;
  font: {
    family: PoppinsRegular;
    size: 13px; }
  .bold {
    font: {
      family: PoppinsBold;
      size: 20px; } }
  p {
    margin-bottom: 5px; } }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  overflow-y: scroll; }

.close-modale {
  background: url('/img/cross.png') center / 28px no-repeat $orange;
  border: 0;
  cursor: pointer;
  height: 56px;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 56px;
  &:hover, &:active, &:focus {
    outline: none; } }


/*  Mon Compte */
.left-col, .right-col {
  display: inline-block;
  float: left;
  width: 50%; }

/* Left Column */

.left-col {
  border-right: 1px solid $dark-blue;
  color: $dark-grey;
  padding-right: 30px;
  .custom-react-select {
    width: 100%; } }

.actual-state-block {
  margin-bottom: 10px; }
.actual-state {
  display: inline-block;
  width: 50%;
  span {
    font: {
      family: PoppinsBold;
      size: 20px; } } }

.paiement-detail {
  background-color: $clear-white;
  padding: 13px 20px;
  margin-top: 10px;
  .paiement-detail-description {
    font: {
      family: PoppinsMedium;
      size: 11px; }
    line-height: 14px;
    margin: 15px 0; } }

/* Right Column */
.right-col {
  padding: {
    left: 30px;
    top: 80px; }
  img {
    display: block;
    margin: 30px auto 0 auto;
    width: 160px; } }

.amount {
  background-color: $clear-white;
  border: 1px solid $clear-white;
  font: {
    family: PoppinsRegular;
    size: 13px; }
  height: 34px;
  margin-top: 10px;
  margin-right: 3px;
  outline: none;
  padding-left: 15px; }

@media screen and (max-width: 768px) {
  .Modal {
    padding: 20px; }
  .actual-state {
    margin-bottom: 10px;
    width: 100%; }

  .left-col {
    padding-right: 20px; }
  .right-col {
    padding-left: 20px; } }

@media (max-width: 577px) {
  .close-modale {
    background-size: 25px;
    height: 45px;
    width: 45px; }
  .left-col, .right-col {
    padding: 0;
    width: 100%; }
  .left-col {
    border: 0;
    border-bottom: 1px solid $dark-blue;
    padding-bottom: 20px;
    margin-bottom: 20px; }

  .right-col {
    text-align: center;
    p {
      width: 100%; }
    .orange-button {
      display: inline-block;
      padding: 2px 15px;
      width: auto; }
    img {
      margin-top: 20px; } } }
