$path: "./fonts/";

@font-face {
  font-family: "PoppinsBold";
  src: url($path + 'poppins-bold.eot') format("eot"), url($path + 'poppins-bold.woff') format("woff"),url($path + 'poppins-bold.ttf') format("truetype"); }


@font-face {
  font-family: "PoppinsBoldItalic";
  src: url($path + 'poppins-bolditalic.eot') format("eot"), url($path + 'poppins-bolditalic.woff') format("woff"),url($path + 'poppins-bolditalic.ttf') format("truetype"); }


@font-face {
  font-family: "PoppinsRegular";
  src: url($path + 'poppins-regular.eot') format("eot"), url($path + 'poppins-regular.woff') format("woff"),url($path + 'poppins-regular.ttf') format("truetype"); }

@font-face {
  font-family: "PoppinsLight";
  src: url($path + 'poppins-light.eot') format("eot"), url($path + 'poppins-light.woff') format("woff"),url($path + 'poppins-light.ttf') format("truetype"); }

@font-face {
  font-family: "PoppinsItalic";
  src: url($path + 'poppins-italic.eot') format("eot"), url($path + 'poppins-italic.woff') format("woff"),url($path + 'poppins-italic.ttf') format("truetype"); }

@font-face {
  font-family: "PoppinsMedium";
  src: url($path + 'poppins-medium.eot') format("eot"), url($path + 'poppins-medium.woff') format("woff"),url($path + 'poppins-medium.ttf') format("truetype"); }

@font-face {
  font-family: "PoppinsMediumItalic";
  src: url($path + 'poppins-mediumitalic.eot') format("eot"), url($path + 'poppins-mediumitalic.woff') format("woff"),url($path + 'poppins-mediumitalic.ttf') format("truetype"); }

@font-face {
  font-family: "PoppinsSemibold";
  src: url($path + 'poppins-semibold.eot') format("eot"), url($path + 'poppins-semibold.woff') format("woff"),url($path + 'poppins-semibold.ttf') format("truetype"); }

@font-face {
  font-family: "ProximaNovaBold";
  src: url($path + 'proxima-nova-bold.eot') format("eot"), url($path + 'proxima-nova-bold.woff') format("woff"),url($path + 'proxima-nova-bold.ttf') format("truetype"); }
