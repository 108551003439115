@import 'variables';

.current-balance {
  background-color: $clear-white;
  color: $dark-grey;
  margin-bottom: 20px;
  padding: 13px 20px;
  position: relative;
  width: 445px;
  p {
    font: {
      family: PoppinsRegular;
      size: 13px; }
    margin-bottom: 10px; }
  .balance-amount {
    font: {
      family: PoppinsBold;
      size: 20px; } }

  button {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 15px;
    width: 165px; } }

.historique-bloc {
  font: {
    family: PoppinsRegular;
    size: 13px; }
  margin-bottom: 28px;
  position: relative;
  p {
    margin-bottom: 10px; } }

.download-bloc {
  margin-bottom: 15px;
  position: absolute;
  right: 0;
  top: 0; }


@media (max-width: 577px) {

  .current-balance {
    text-align: center;
    width: 100%;
    .balance-amount {
      margin-bottom: 10px; }
    button {
      position: static; } }

  .download-bloc {
    position: static;
    .orange-button {
      display: inline-block;
      width: 160px; } }
  .historique-bloc {
    position: static;
    text-align: center;
    .custom-react-select {
      display: inline-block; } } }
