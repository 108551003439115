@import 'variables';
@import 'mixins';

.login-page-wrapper {
  background: url('/img/couple.jpg') center / cover no-repeat;
  height: calc(100% - 56px);
  padding-top: 40px; }

.login-page {
  height: 100%; }

.login-logo {
  display: block;
  margin: 0 auto;
  width: 172px; }

.login-block {
  position: absolute;
  right: 0;
  text-align: left;
  top: 80px;
  width: 255px; }

.login-title {
  background-color: $blue;
  color: #fff;
  height: 80px;
  font: {
    family: PoppinsMedium;
    size: 13px; }
  padding-top: 20px;
  text-align: center;
  span {
    display: block;
    font: {
      family: PoppinsBold;
      size: 20px; }
    margin-bottom: 10px; } }

.login-container {
  background-color: $clear-white;
  padding: 20px;
  label {
    color: $dark-grey;
    font: {
      family: PoppinsMedium;
      size: 13px; }
    display: block;
    margin-bottom: 10px; }

  input[type="text"], input[type="password"], input[type="email"] {
    background-color: #FFF;
    border: 0;
    @include border-radius(5px);
    font: {
      family: PoppinsRegular;
      size: 13px; }
    height: 37px;
    padding: {
      left: 10px;
      top: 4px; }
    width: 100%; }
  .error {
    color: tomato;
    font: {
      family: PoppinsMedium;
      size: 13px; }
    margin-top: 10px; } }

.login-input-container {
  margin-bottom: 20px;
  &:nth-child(2) {
    margin-bottom: 0; }
  &.lost-password {
    &:nth-child(2) {
      margin-bottom: 20px; }
    &:nth-child(3) {
      margin-bottom: 0; } } }

.lost-password-title {
  text-align: center;
  margin-bottom: 10px; }

.login-footer {
  background-color: $blue;
  padding: 20px;
  button {
    cursor: pointer; } }

.login-connect-container {
  background-color: $orange;
  @include border-radius(5px);
  border: 0;
  color: #FFF;
  font: {
    family: PoppinsMedium;
    size: 13px; }
  height: 37px;
  padding-top: 4px;
  text-align: center;
  width: 100%; }


.login-forgot-container {
  cursor: pointer;
  color: #FFF;
  font: {
    family: PoppinsSemiBold;
    size: 11px; }
  margin-top: 12px;
  text-decoration: none; }

.ie-popin {
  background-color: rgba(0,0,0,0.6);
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1001;
  .ie-popin__block {
    position: absolute;
    background-color: #FFF;
    margin-left: -280px;
    width: 560px;
    left: 50%;
    top: 50%;
    margin-top: -109px;
    padding: 35px 50px;
    text-align: left;
    p {
      font: {
        family: "PoppinsBold";
        size: 17px; }
      line-height: 20px;
      margin-bottom: 20px; }

    ul {
      li {
        font-size: 13px;
        margin-bottom: 8px;
        a {
          color: $blue;
          font-family: "PoppinsBold"; } } } }
  .popin-close {
    background-color: orange;
    border-radius: 50%;
    font: {
      family: "PoppinsBold";
      size: 17px; }
    color: #FFF;
    cursor: pointer;
    height: 25px;
    line-height: 30px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    width: 25px; } }

@media screen and (max-width: 1200px) {
  .login-block {
    right: 15px; } }
@media screen and (max-width: 767px) {
  .login-page {
    height: auto; }

  .login-block {
    margin: 0 auto;
    position: static;
    padding-bottom: 50px; }

  .login-title, .login-container, .login-footer {
    background-color: transparent; }

  .login-container {
    label {
      color: #FFF; } }

  .ie-popin {
    .ie-popin__block {
      position: absolute;
      margin-left: 0;
      width: 100%;
      left: 0;
      top: 50px;
      margin-top: 0; } } }

@media (max-width: 577px) {
  .login-block {
    width: 100%; } }
