@import 'variables';
@import 'mixins';

.blocks-cont {
  .block {
    display: inline-block;
    float: left;
    width: calc(50% - 15px);
    margin: 15px;
    padding: 20px;
    background-color: $clear-white;
    &:nth-child(even) {
      margin-right: 0; }
    &:nth-child(odd) {
      margin-left: 0; }
    &:first-child {
      .block-title {
        background-image: url('/img/grey-credit-card.png');
        line-height: 34px; } }
    &:nth-child(2) {
      .block-title {
        background-image: url('/img/grey-consommations.png'); } }
    &:nth-child(3) {
      .block-title {
        background-image: url('/img/grey-documents.png'); } }
    &:nth-child(4) {
      .block-title {
        background-image: url('/img/grey-demandes.png');
        line-height: 34px; } } } }
.block-title {
  background: {
    position: left 2px;
    repeat: no-repeat;
    size: auto 25px; }
  font: {
    family: PoppinsBold;
    size: 14px; }
  line-height: 15px;
  height: 34px;
  padding-left: 30px; }
.block-metric {
  font: {
    family: PoppinsLight;
    size: 30px; }
  line-height: 46px;
  text-align: center;
  margin: {
    bottom: 14px;
    top: 10px; } }

.message-side {
  .messages-side-title {
    background: {
      image: url('/img/messages@2x.png');
      color: $dark-blue;
      position: 10px center;
      repeat: no-repeat;
      size: 25px; }
    color: #FFF;
    height: 60px;
    font: {
      family: PoppinsBold;
      size: 15px; }
    padding: 16px 22px 8px 42px;
    position: relative; }
  .message-count {
    background-color: $orange;
    color: #FFF;
    font: {
      family: PoppinsBold;
      size: 13px; }
    @include border-radius(50%);
    line-height: 35px;
    height: 30px;
    margin-top: -15px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 50%;
    width: 30px; }

  li {
    color: $dark-grey;
    padding: 20px;
    position: relative;
    &:nth-child(even) {
      background-color: $light-grey-2; }
    &:nth-child(odd) {
      background-color: $clear-white; }
    a {
      color: $dark-grey;
      font: {
        family: PoppinsSemibold;
        size: 11px; }
      text-decoration: underline; } }

  .message-side-date {
    background-color: $light-grey;
    @include border-radius(3px);
    color: #FFF;
    display: inline-block;
    font: {
      family: PoppinsBold;
      size: 12px; }
    padding: 4px 8px 0px;
    line-height: 17px; }

  .message-side-hour {
    color: $dark-grey;
    font: {
      family: PoppinsLight;
      size: 13px; }
    line-height: 23px;
    position: absolute;
    right: 20px;
    top: 20px; }
  .message-side-subject {
    font: {
      family: PoppinsBold;
      size: 15px; }
    margin: 10px 0; }
  p {
    font: {
      family: PoppinsMedium;
      size: 13px; }
    line-height: 16px;
    margin-bottom: 10px; }

  .message-side-footer {
    background-color: $dark-blue;
    padding: 20px; } }

@media screen and (max-width: 991px) {
  .blocks-cont {
    .block {
      padding: 10px;
      &:first-child, &:nth-child(4) {
        .block-title {
          line-height: 29px; } } }
    .orange-button {
      font-size: 11px;
      height: auto;
      line-height: 17px;
      padding: 7px 5px; } }
  .block-title {
    background-size: auto 20px;
    font-size: 12px;
    height: 31px;
    padding-left: 23px; }
  .block-metric {
    font-size: 22px;
    line-height: 33px;
    white-space: nowrap; }

  .message-side {
    .messages-side-title {
      background-image: none;
      padding-left: 10px; }
    li {
      padding: 10px; }
    .message-side-hour {
      top: 10px; }
    .message-side-subject {
      font-size: 13px; }
    p {
      font-size: 12px;
      line-height: 15px; }
    .message-side-footer {
      padding: 10px; } } }
.blocks-cont .block:first-child .block-title {}

@media screen and (max-width: 768px) {
  .message-side {
    .messages-side-title {
      background-image: url('/img/messages@2x.png');
      height: 44px;
      padding-left: 42px; } }

  .Modal {
    margin-top: 66px;
    width: calc(100% - 20px); } }

@media (max-width: 577px) {
  .blocks-cont {
    .block {
      margin: 0;
      margin-bottom: 15px;
      padding: 20px;
      width: 100%; } }
  .block-title {
    font-size: 15px; }
  .block-metric {
    font-size: 30px; } }

