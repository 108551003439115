@import 'variables';

.header {
  background: {
    image: url('/img/header-couple.png'), linear-gradient(to right, $blue, $dark-blue);
    position: 68% 0px;
    size: auto 100%;
    repeat: no-repeat; }
  height: 120px;
  padding-top: 30px;
  text-align: center;
  width: 100%; }

.logo {
  position: absolute;
  left: 15px;
  top: 0;
  width: 107px; }
.page-title {
  color: #FFF;
  display: inline-block;
  font: {
    family: PoppinsLight;
    size: 20px; }
  margin-top: 20px; }

.right-header {
  position: absolute;
  right: 14px;
  top: 10px;
  width: 265px; }
.notifs {
  background: {
    color: $orange;
    image: url('/img/cloche.png');
    repeat: no-repeat;
    position: 10px center;
    size: 17px; }
  @include border-radius(3px);
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font: {
    family: PoppinsBold;
    size: 15px; }
  height: 42px;
  line-height: 46px;
  margin: 0 10px;
  padding: {
    left: 33px;
    right: 10px; }
  @include transition(background-color 0.3s ease-in-out);
  &:hover {
    background-color: darken($orange, 10%);
    color: #FFF;
    text-decoration: none; } }

.logout {
  height: 50px;
  display: inline-block;
  button {
    background: {
      color: $blue;
      image: url('/img/cadenas.png');
      position: 15px center;
      size: 19px;
      repeat: no-repeat; }
    border: 0;
    @include border-radius(3px);
    color: #FFF;
    cursor: pointer;
    font: {
      family: PoppinsBold;
      size: 13px; }
    height: 42px;
    outline: none;
    line-height: 48px;
    padding: 0px 20px 10px 44px;
    @include transition(background-color 0.3s ease-in-out);
    &:hover {
      background-color: darken($blue, 10%); } } }

@media screen and (max-width: 991px) {
  .page-title {
    font-size: 15px;
    margin-top: 24px; } }

@media screen and (max-width: 768px) {
  .burger {
    background: {
      image: url('/img/burger-menu.png');
      size: 19px;
      repeat: no-repeat;
      position: center; }
    border-right: 1px solid $dark-blue;
    height: 56px;
    position: absolute;
    left: 0;
    top: 0;
    width: 56px;
    &.open {
      background-image: url('/img/cross.png'); } }
  .header {
    background-image: linear-gradient(to right, $blue, $dark-blue);
    height: 56px;
    color: #FFF;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; }
  .logo {
    position: relative;
    top: 4px;
    left: 0;
    margin-right: 10px;
    width: 20px; }

  .notifs {
    margin-right: 0;
    right: 6px;
    position: absolute;
    top: 7px;
    width: 60px; }

  .mobile-logo-block {
    padding-top: 12px; }
  .logout {
    display: none; } }

