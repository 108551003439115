@import 'variables';
@import 'mixins';

.maintenance-page {
  height: 100%;
  padding-top: 100px;
  img {
    margin-bottom: 100px; }
  p {
    text-align: center; } }
.maintenance-page-wrapper {
  height: 100%; }
