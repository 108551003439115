@import 'variables';
@import 'mixins';

.status-request-block {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  width: 320px;
  > div {
    font-size: 13px;
    font-family: PoppinsRegular;
    margin-bottom: 15px;
    strong {
      font-size: 20px; } } }

.request-table {
  margin-top: 70px;
  margin-bottom: 20px; }

.orange.consult-link {
  border: 0;
  cursor: pointer;
  font: {
    family: PoppinsSemibold;
    size: 12px; }
  border-bottom: 1px solid $orange;
  outline: none;
  &:hover {
    color: darken($orange, 10%);
    text-decoration: none; } }

.list-rappel {
  font: {
    family: PoppinsMedium;
    size: 12px; }
  line-height: 15px;
  margin-bottom: 15px;
  ul {
    list-style-type: disc;
    margin-left: 15px; } }

.request-form, .page-content.form-content {
  .custom-react-select {
    width: 100%;
    .css-2o5izw, .css-2o5izw:hover {
      background-color: $clear-white;
      border: 1px solid $select-border; }
    .css-vj8t7z {
      background-color: $clear-white;
      border: 1px solid $select-border;
      &:hover {
        border-color: $select-border; } }

    .css-1492t68 {
      color: $dark-grey; }

    /* Arrow */
    .css-1ep9fjw, .css-1uq0kb5 {
      background: {
        image: url('/img/caret-black.png'); } }

    .css-1492t68, .css-xp4uvy {
      color: $placeholder; }
    input {
      position: absolute;
      top: 0; } }

  textarea {
    border: $select-border;
    background-color: $clear-white;
    color: $placeholder;
    font: {
      family: PoppinsSemibold;
      size: 13px; }
    height: 115px;
    outline: none;
    padding: 10px;
    width: 100%; }

  .orange-button {
    display: inline-block; } }

.request-single {
  a {
    color: $orange;
    display: inline-block;
    font: {
      family: PoppinsSemibold;
      size: 12px; }
    margin-bottom: 10px; }
  .request-title {
    background-color: $dark-blue;
    color: #FFF;
    height: 29px;
    font: {
      family: PoppinsMedium;
      size: 13px; }
    line-height: 30px;
    padding-left: 12px; }
  li {
    &:nth-child(even), &.answer {
      background-color: $clear-white; }
    em {
      font-family: PoppinsItalic;
      strong {
        font-family: PoppinsBoldItalic; } }
    p {
      font: {
        family: PoppinsMedium;
        size: 12px; }
      line-height: 15px;
      margin-bottom: 10px; }
    > div {
      display: inline-block;
      float: left;
      padding: 12px;
      &:first-child {

        width: 190px; }
      &:last-child {
        border-left: 1px solid $dark-blue;
        width: calc(100% - 190px); } } } }

@media screen and (max-width: 991px) {
  .status-request-block {
    > div {
      font-size: 11px;
      strong {
        font-size: 15px; } } } }

@media screen and (max-width: 768px) {
  .status-request-block {
    position: static;
    text-align: left; } }

@media (max-width: 577px) {
  .status-request-block {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    width: 100%; }
  .request-table.tab {
    margin-top: 20px; }
  .orange-button.create-request {
    font-size: 12px;
    line-height: 15px;
    padding: 9px 30px 8px 40px; }
  a.orange.consult-link {
    display: inline-block;
    width: auto; }

  .request-cont {
    margin: 0 -15px; }

  .request-single {
    li {
      > div {
        padding: 10px;
        width: 100%;
        &:first-child {
          border-bottom: 1px solid $dark-blue;
          padding-bottom: 0;
          padding-top: 12px;
          width: 100%; }
        &:last-child {
          border-left: 0; } } } } }

