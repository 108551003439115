@import 'variables';
@import 'mixins';

body {
  font-family: PoppinsRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

/****************************/
/* REUSABLE */
/****************************/
strong {
  font-family: PoppinsBold; }

h1 {
  color: $dark-grey;
  font: {
    family: PoppinsBold;
    size: 20px; }
  margin-bottom: 12px; }

h3 {
  margin-bottom: 10px; }

.home-link {
  color: #FFF;
  text-decoration: none;
  &:hover, &:active, &:focus {
    color: #FFF;
    text-decoration: none; } }
.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

.orange {
  color: $orange; }

.push-right {
  float: right; }

.align-right {
  text-align: right; }

.center {
  text-align: center; }

.container {
  position: relative; }

.orange-button, .blue-button, .disabled-button {
  border: 0;
  @include border-radius(3px);
  color: #FFF;
  cursor: pointer;
  display: block;
  font: {
    family: PoppinsSemibold;
    size: 13px; }
  height: 38px;
  line-height: 37px;
  padding-top: 2px;
  text-align: center;
  width: 100%;
  @include transition(background-color 0.3s ease-in-out);
  &:hover, &:focus, &:active {
    color: #FFF;
    outline: none;
    text-decoration: none; }
  &.download {
    background: {
      image: url('/img/download.png');
      position: calc(100% - 10px) center;
      repeat: no-repeat;
      size: 35px; }
    @include border-radius(0);
    font: {
      family: PoppinsMedium;
      size: 12px; }
    padding-left: 15px;
    text-align: left;
    width: 635px; }
  &.display-all {
    display: inline-block;
    padding: 2px 15px;
    width: auto; }
  &.create-request {
    background: {
      image: url('/img/plus.png');
      position: 10px center;
      repeat: no-repeat;
      size: 20px; }
    display: inline-block;
    height: auto;
    line-height: 17px;
    padding: 12px 30px 12px 40px;
    text-align: left;
    width: 220px; } }

.orange-button {
  background-color: $orange;
  &:hover, &:focus, &:active {
    background-color: darken($orange, 10%); } }

.blue-button {
  background-color: $blue;
  width: 125px;
  &:hover, &:focus, &:active {
    background-color: darken($blue, 10%); } }

.disabled-button {
  background-color: $dark-grey;
  cursor: not-allowed;
  pointer-events: none;
  &:hover, &:focus, &:active {
    background-color: darken($dark-grey, 10%); } }

@media (min-width: 1200px) {
  .container {
    max-width: none;
    width: 1100px; } }

@media (max-width: 1200px) {
  .container {
    max-width: none;
    width: 100%; } }

@media (max-width: 992px) {
  .container {
    max-width: none; } }

@media (max-width: 768px) {
  .container {
    max-width: none; } }

@media (max-width: 576px) {
  .container {
    max-width: none;
    width: 100%; } }

@media (min-width: 1200px) {
  .container {
    max-width: none; } }

@media (min-width: 992px) {
  .container {
    max-width: none; } }

@media (min-width: 768px) {
  .container {
    max-width: none; } }

@media (min-width: 576px) {
  .container {
    max-width: none; } }

.content-wrapper {
  padding: 24px; }

.page-content {
  position: relative; }

.tooltip {
  position: relative;
  display: inline-block; }

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #394959;
  color: #fff;
  text-align: center;
  font: {
    family: -apple-system, BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif;
    size: 12px; }
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -60px; }

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #394959 transparent transparent transparent; }

.tooltip:hover .tooltiptext {
  visibility: visible; }

.top-bar {
  background-color: #394959;
  color: #fff;
  height: 43px;
  font: {
    family: -apple-system, BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif; }
  line-height: 43px;
  padding-left: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; }

.navigation-sidebar {
  display: inline-block;
  float: left;
  height: 100vh;
  position: relative;
  word-break: break-all;
  width: 200px;
  a {
    background-color: transparent;
    border: 1px solid transparent;
    color: $fb-grey;
    display: block;
    font: {
      family: -apple-system, BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif;
      size: 12px;
      weight: bold; }
    outline: none;
    padding: 5px 10px 9px 10px;
    text-align: left;
    transition: all 100ms ease-in;
    &:hover {
      background-color: #f6f7f9;
      border: 1px solid #dddfe2;
      border-radius: 2px; }
    &.active {
      background-color: #fff;
      border: 1px solid #dddfe2;
      border-radius: 2px; } } }

.navigation-wrapper {
  position: fixed;
  top: 53px;
  width: 200px; }

.nav-icon {
  display: inline-block;
  height: 16px;
  position: relative;
  top: 3px;
  &-wrapper {
    display: inline-block;
    width: 25px; } }

.main-content {
  display: inline-block;
  float: left;
  height: calc(100vh - 53px);
  padding: {
    top: 15px;
    left: 15px; }
  position: relative;
  top: 43px;
  width: calc(100% - 200px);
  > div {
    height: 100%; } }
.page-name {
  border-bottom: 1px solid $block-border;
  color: #4b4f56;
  font: {
    size: 14px;
    weight: 600; }
  height: 47px;
  line-height: 47px;
  padding: 0 12px;
  width: 100%; }

.fb-wrap {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid $block-border;
  margin-bottom: 16px;
  &.form-page {
    height: auto; } }

.item-list {
  overflow: hidden;
  overflow-y: auto; }

.list-row {
  margin: 0 -15px; }

.links {
  clear: both;
  display: block;
  height: 28px;
  margin-top: 15px;
  width: 100%; }

.account-dropdown.ui.multiple.dropdown {
  >.label {
    margin: 0;
    margin-right: 15px;
    padding: 0;
    border: 0;
    box-shadow: none; } }

.App {
  display: block;
  width: 100%;
  height: 100%; }
.page-body {
  background-color: $white;
  display: inline-block;
  padding: 30px 0;
  height: 100%;
  width: 100%; }


.hello {
  color: $dark-grey;
  font: {
    family: PoppinsLight;
    size: 20px; }
  line-height: 24px;
  margin-bottom: 10px;
  span {
    font-family: PoppinsBold; } }

.file {
  color: $dark-grey;
  font: {
    family: PoppinsMedium;
    size: 15px; }
  margin-bottom: 15px; }

@media screen and (max-width: 991px) {
  .orange-button {
    &.download {
      width: 100%; } } }

@media screen and (max-width: 768px) {
  .page-body {
    margin-top: 56px; } }


@media (max-width: 577px) {
  .page-body {
    margin-top: 40px; }
  .hello {
    font-size: 14px;
    text-align: center; }
  .file {
    text-align: center;
    font-size: 13px; }
  h1 {
    text-align: center; }
  .mobile-center {
    text-align: center; } }
