$body: #1f2128;
$white: #ffffff;
$fb-grey: #1d2129;
$dark-grey: #4c4c4c;
$block-border: #dddfe2;
$dark-blue: #123453;
$blue: #2879ae;
$nav-blue: #25527c;
$orange: #ff8e56;
$light-grey: #c1bebe;
$light-grey-2: #e0e0e0;
$clear-white: #f1f1f1;
$footer: #292929;

$placeholder: #767676;
$select-border: #E2E2E2;
