@import 'variables';
@import 'mixins';

.form-content {
  label {
    display: block;
    font: {
      family: PoppinsRegular;
      size: 13px; }
    margin-bottom: 5px; }

  input[type="text"], input[type="number"] {
    background-color: $clear-white;
    border: 1px solid $clear-white;
    font: {
      family: PoppinsRegular;
      size: 13px; }
    height: 34px;
    outline: none;
    padding-left: 15px;
    width: 100%; }

  .field-row {
    margin-bottom: 17px;
    .half {
      display: inline-block;
      float: left;
      width: 50%;
      &:first-child {
        padding-right: 10px; }
      &:last-child {
        padding-left: 10px; } } }

  .orange-button {
    width: 255px; } }

.steps {
  border: 1px solid $dark-blue;
  color: $dark-blue;
  padding: 8px;
  font: {
    family: PoppinsBold;
    size: 11px; }
  line-height: 15px;
  margin-bottom: 15px; }

.SingleDatePickerInput__withBorder {
  border: 1px solid $clear-white; }

.DateInput.DateInput_1 {
  position: relative;
  width: 255px;
  input {
    cursor: pointer; }
  &:after {
    background: {
      color: $orange;
      image: url('/img/calendar.png');
      repeat: no-repeat;
      position: center;
      size: auto 26px; }
    @include border-radius(3px);
    content: "";
    width: 63px;
    height: 36px;
    right: -1px;
    top: -1px;
    pointer-events: none;
    position: absolute; } }


@media (max-width: 577px) {
  .form-content {
    .field-row {
      .half {
        width: 100%;
        &:first-child, &:last-child {
          padding: 0; }
        &:first-child {
          margin-bottom: 15px; } } }
    .orange-button {
      width: 100%; } }
  .request-form .orange-button {
    width: 100%; } }
