@import 'variables';
@import 'mixins';

table {
  width: 100%;
  thead {
    background-color: $dark-blue;
    th {
      color: #FFF;
      font: {
        family: PoppinsMedium;
        size: 13px; }
      padding: 10px 10px 7px 10px; } }

  tbody {
    td {
      color: $footer;
      font: {
        family: PoppinsMedium;
        size: 12px; }
      line-height: 15px;
      padding: 10px 5px;
      vertical-align: middle; }
    tr:nth-child(even) {
      background-color: $clear-white; } }
  .download-pdf {
    cursor: pointer;
    height: 22px;
    padding-left: 7px;
    img {
      height: 100%; } } }

.tab.consommation {
  font: {
    family: PoppinsMedium;
    size: 12px; }
  margin-bottom: 15px;
  header {
    &.table-row {
      background-color: $dark-blue; }
    .table-cell {
      color: #FFF;
      font: {
        family: PoppinsMedium;
        size: 13px; } } } }

.display-table {
  border-collapse: collapse;
  display: table;
  width: 100%; }

.table-row {
  display: table-row; }

.table-cell {
  border: 1px solid $dark-blue;
  border-top: none;
  display: table-cell;
  padding: 10px;
  line-height: 15px;
  &:last-child {
    width: 130px; } }

.observation-commentary {
  border: {
    right: 1px solid $dark-blue;
    left: 1px solid $dark-blue;
    bottom: 1px solid $dark-blue; }
  padding: 15px; }

.conso-table-row:nth-of-type(even) {
  background-color: $clear-white; }

.tab.request-table {
  header.table-row {
    .table-cell {
      background-color: $dark-blue;
      color: #FFF; } }
  .table-cell {
    color: $footer;
    font: {
      family: PoppinsMedium;
      size: 12px; }
    vertical-align: top;
    border: 0;
    &:nth-child(2) {
      background-color: $clear-white; } }

  .table-row:nth-of-type(even) {
    .table-cell {
      &:first-child, &:last-child {
        background-color: $clear-white; }
      &:nth-child(2) {
        background-color: #FFF; } } }

  .action-cell {
    padding: 20px 30px;
    min-width: 225px;
    text-align: center;
    > .blue-button {
      margin: 0 auto;
      margin-bottom: 20px; }
    * {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0; } } }
  .demande-cell {
    * {
      white-space: nowrap;
      display: block;
      margin-bottom: 5px; } } }
sup {
  font-size: 10px; }
@media (max-width: 577px) {
  .tab {
    &.consommation {
      margin: 0 -15px 15px;
      header.table-row {
        display: none; }
      .table-cell {
        display: inline-block;
        float: left;
        width: 50%;
        height: 150px;
        &:nth-child(odd) {
          border-right: 0; }
        &:first-child, &:nth-child(2) {
          border-top: 1px solid $dark-blue; } } }
    &.request-table {
      margin: 0 -15px 20px;
      header {
        display: none; }
      .table-cell {
        background-color: transparent !important;
        display: inline-block;
        padding: 10px 10px 0;
        width: 100%; }
      .table-row {
        &:nth-of-type(odd) {
         background-color: $clear-white; } }
      .action-cell {
        padding: 20px 0;
        text-align: center;
        * {
          display: inline-block;
          margin: 0 5px; } } } }

  .conso-table-row {
    .table-cell {
      display: inline-block;
      float: left;
      width: 50%;
      height: 150px;
      &:first-child, &:nth-child(2) {
        border-top: 0px !important; }
      > div {
        line-height: 14px;
        margin-bottom: 5px; } } } }
