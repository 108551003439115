@import 'variables';
@import 'mixins';
.menu {
  display: inline-block;
  width: 100%;
  ul {
    li {
      a {
        background: {
          position: 12px center;
          repeat: no-repeat;
          size: 17px auto;
          color: $nav-blue; }
        border-bottom: 1px solid $dark-blue;
        color: $white;
        display: block;
        font: {
          family: PoppinsBold;
          size: 13px; }
        line-height: 17px;
        padding: 23px 10px 19px 37px;
        text-decoration: none;
        @include transition(background-color 0.3s ease-in-out);
        &:hover, &:active {
          color: $white;
          background-color: $dark-blue;
          text-decoration: none; } }
      &.selected {
        a {
          background-color: $dark-blue; } }
      &:last-child {
        a {
          border-bottom: none; } }
      &:first-child {
        a {
          background-image: url('/img/accueil.png'); } }

      &:nth-child(2) {
        a {
          background-image: url('/img/credit-card.png'); } }

      &:nth-child(3) {
        a {
          background-image: url('/img/documents.png'); } }

      &:nth-child(4) {
        a {
          background-image: url('/img/consommations.png'); } }

      &:nth-child(5) {
        a {
          background-image: url('/img/demandes.png'); } }

      &:nth-child(6) {
        a {
          background-image: url('/img/infos.png'); } }
      &:nth-child(7) {
        a {
          background-image: url('/img/loupe.png'); } } } } }


@media screen and (max-width: 768px) {
  .menu {
    background-color: #FFF;
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    &.hide {
      display: none; } } }
